import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'bulma/css/bulma.min.css';  // Import Bulma CSS
import '../css/UserListPage.css';   // Ensure your custom CSS is imported
import { Config } from '../.env.js';
const UserList = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get(`${Config.API_URL}/api/data`)
            .then(response => setData(response.data))
            .catch(error => console.error('Error fetching users:', error));
    }, []);

    if (!data || !data.profile) {
        return <div className="container">Loading...</div>;
    }

    return (
        <div className="container">
            <h1 className="title is-3">Instagram Users</h1>
            <div className="columns is-multiline">
                {data.profile.map(profile => (
                    <div key={profile.instagramAccountId} className="column is-one-quarter">
                        <div className="card">
                            <Link to={`/user/${profile.instagramAccountId}`}>
                                <div className="card-image">
                                    <figure className="image is-128x128">
                                        <img 
                                            src={profile.profilePictureUrl || 'default-profile.png'} 
                                            alt={profile.username} 
                                            className="is-rounded" 
                                        />
                                    </figure>
                                </div>
                                <div className="card-content">
                                    <div className="media">
                                        <div className="media-content">
                                            <p className="title is-4">{profile.username}</p>
                                            <p className="subtitle is-6">{profile.followersCount} Followers</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserList;
