import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
import EngagementChart from './components/EngagementChart';
// import DemographicPieChart from './components/DemographicPieChart';
// import AgeRangeLineChart from './components/AgeRangeLineChart';
// import CountryCodeBarChart from './components/CountryCodeBarChart';
// import CityDemographicBarChart from './components/CityDemographicBarChart';
// import AudienceDataCharts from './components/AudienceDataCharts';
// import { NavLink } from 'react-router-dom';
import "../css/UserDetailPage.css";
import { Config } from '../.env.js';
import { FaLinkedin, FaFacebook, FaYoutube, FaInstagram, FaTwitter, FaPinterest, FaSnapchatGhost, FaTumblr, FaRedditAlien, FaWhatsapp, FaTelegramPlane, FaTiktok } from 'react-icons/fa';


const websiteIcons = {
    'linkedin.com': <FaLinkedin className="icon is-medium" />,
    'facebook.com': <FaFacebook className="icon is-medium" />,
    'instagram.com': <FaInstagram className="icon is-medium" />,
    'youtube.com': <FaYoutube className="icon is-medium" />,
    'twitter.com': <FaTwitter className="icon is-medium" />,
    'pinterest.com': <FaPinterest className="icon is-medium" />,
    'snapchat.com': <FaSnapchatGhost className="icon is-medium" />,
    'tumblr.com': <FaTumblr className="icon is-medium" />,
    'reddit.com': <FaRedditAlien className="icon is-medium" />,
    'whatsapp.com': <FaWhatsapp className="icon is-medium" />,
    'telegram.org': <FaTelegramPlane className="icon is-medium" />,
    'tiktok.com': <FaTiktok className="icon is-medium" />,
    // Add more mappings as needed
};

const getIconForWebsite = (url) => {
    const domain = new URL(url).hostname.replace('www.', '');
    return websiteIcons[domain] || null; // Return icon or null if not found
};
// import EngagementChart1 from './components/EngegmentRateText';

const UserProfile = () => {
    const { instagramAccountId } = useParams();
    const [userData, setUserData] = useState(null);
    const [engagementData, setEngagementData] = useState(null);
    const [mediaData, setMediaData] = useState([]);
    const [showMediaData, setShowMediaData] = useState(false);
    const [commentVisibility, setCommentVisibility] = useState({});

    const [demographicData, setDemographicData] = useState([]);
    const [ageRangeData, setAgeRangeData] = useState([]);
    const [countryCodeData, setCountryCodeData] = useState([]);
    const [cityDemographicData, setCityDemographicData] = useState([]);
    const [activeSection, setActiveSection] = useState(null);
    const [showEngagementChart, setShowEngagementChart] = useState(false);
    const [activeMediaId, setActiveMediaId] = useState(null);

    // // Function to handle comments icon click
    // const handleCommentClick = (mediaId) => {
    //     setActiveMediaId(prevId => (prevId === mediaId ? null : mediaId));
    // };

    // const handleCardClick = () => {
    //     setShowEngagementChart(prevState => !prevState);
    // };
    useEffect(() => {
        // Fetch user details and data
        axios.get(`${Config.API_URL}/api/data/${instagramAccountId}`)
            .then(response => setUserData(response.data))
            .catch(error => console.error('Error fetching user details:', error));

        axios.get(`${Config.API_URL}/api/engagements?instagramAccountId=${instagramAccountId}`)
            .then(response => setEngagementData(response.data.data))
            .catch(error => console.error('Error fetching engagement data:', error));

        axios.get(`${Config.API_URL}/api/followers-age-range/${instagramAccountId}`)
            .then(response => setAgeRangeData(response.data))
            .catch(error => console.error('Error fetching followers age range data:', error));
    }, [instagramAccountId]);

    const fetchMediaData = () => {
        axios.get(`${Config.API_URL}/api/media/${instagramAccountId}`)
            .then(response => setMediaData(response.data))
            .catch(error => console.error('Error fetching media data:', error));
    };

    // const fetchDemographicData = () => {
    //     axios.get(`${Config.API_URL}/api/follower-demographics/${instagramAccountId}`)
    //         .then(response => setDemographicData(response.data))
    //         .catch(error => console.error('Error fetching demographic data:', error));
    // };

    // const fetchCountryCodeData = () => {
    //     axios.get(`${Config.API_URL}/api/country-codes/${instagramAccountId}`)
    //         .then(response => {
    //             const countryCodes = response.data[0].totalValue;
    //             setCountryCodeData(countryCodes);
    //         })
    //         .catch(error => console.error('Error fetching country code data:', error));
    // };

    // const fetchCityDemographicData = () => {
    //     axios.get(`${Config.API_URL}/api/city-demographics/${instagramAccountId}`)
    //         .then(response => {
    //             if (response.data.length > 0) {
    //                 const citiesData = response.data[0].cities;
    //                 setCityDemographicData(citiesData);
    //             }
    //         })
    //         .catch(error => console.error('Error fetching city demographic data:', error));
    // };

    const handleShowMediaClick = () => {
        if (!showMediaData) {
            fetchMediaData(); // Fetch media data only when the button is clicked for the first time
        }
        setShowMediaData(prevState => !prevState); // Toggle media data visibility
    };
    const toggleComments = (mediaId) => {
        setCommentVisibility(prevState => ({
            ...prevState,
            [mediaId]: !prevState[mediaId] // Toggle comments visibility for the clicked media
        }));
    };

    // const handleButtonClick = (section) => {
    //     setShowEngagementChart(false);
    //     if (section === activeSection) {
    //         setActiveSection(null);
    //     } else {
    //         setActiveSection(section);
    //         if (section === 'Profile') {
    //             axios.get(`${Config.API_URL}/api/data/${instagramAccountId}`)
    //                 .then(response => setUserData(response.data))
    //                 .catch(error => console.error('Error fetching user details:', error));
    //         } else if (section === 'Medias') {
    //             fetchMediaData();
    //         } else if (section === 'Followers_Demographic') {
    //             fetchDemographicData();
    //         } else if (section === 'Followers_Age_Range') {
    //             axios.get(`${Config.API_URL}/api/followers-age-range/${instagramAccountId}`)
    //                 .then(response => setAgeRangeData(response.data))
    //                 .catch(error => console.error('Error fetching followers age range data:', error));
    //         } else if (section === 'Country_Code') {
    //             fetchCountryCodeData();
    //         } else if (section === 'City_Demographic') {
    //             fetchCityDemographicData();
    //         } else if (section === 'Audience_Data') {

    //         }
    //         else if (section === 'metrics_data') {
    //             axios.get(`${Config.API_URL}/api/data/${instagramAccountId}`)
    //                 .then(response => setUserData(response.data))
    //                 .catch(error => console.error('Error fetching user details:', error));
    //         }
    //     }
    // };

    if (!userData || !engagementData) {
        return <div className="container">Loading...</div>;
    }

    // Calculate engagement rate
    const totalEngagement = engagementData.reduce((total, item) => total + item.engagementRate, 0);
    const averageEngagementRate = engagementData.length > 0 ? (totalEngagement / engagementData.length) : 0;

    return (
        <div className="container">
            <div className="columns is-mobile" style={{ height: '100vh' }}>
                {/* Left half where the content will be shown */}
                <div className="column is-half" style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="card profile-card" style={{ width: 'auto', padding: '20px', marginBottom: '20px', borderRadius: "30px", textAlign: 'center', display: 'inline-block', marginLeft: '-20px', marginTop: '20px' }}>
                        {/* Profile Picture */}
                        <figure className="image is-128x128" style={{ margin: '0 auto' }}>
                            <img src={userData.profilePictureUrl || 'default-profile.png'} alt={userData.username} />
                        </figure>

                        {/* User Info */}
                        <div className="content" style={{ marginTop: '20px' }}>
                            <h2 className="title is-4">@{userData.username}</h2>
                            <p className="subtitle is-6">{userData.category}</p>
                            <p className="subtitle is-6">{userData.biography}</p>

                            {/* Website Links */}
                            {userData.website && (
                                <div className="website-section" style={{ marginTop: '10px' }}>
                                    {Array.isArray(userData.website) ? (
                                        userData.website.map((url, index) => (
                                            <a key={index} href={url} target="_blank" rel="noopener noreferrer" className="icon-container" style={{ marginRight: '10px' }}>
                                                {getIconForWebsite(url)}
                                            </a>
                                        ))
                                    ) : (
                                        <a href={userData.website} target="_blank" rel="noopener noreferrer" className="icon-container" style={{ marginRight: '10px' }}>
                                            {getIconForWebsite(userData.website)}
                                        </a>
                                    )}
                                </div>
                            )}
                            <div className="columns is-centered">
                                <div className="column is-narrow">
                                    <button className="button is-danger is-medium" style={{ marginTop: "60px" }} >
                                        Collaborate with Me
                                        <span className="icon" style={{ marginLeft: "5px" }}>
                                            →
                                        </span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="column">
                    <div className="card" style={{ marginTop: "20px", width: '900px', marginLeft: "-130px", overflowY: "auto" }}>
                        <div className="card-content">
                            <h2 className="title is-4 has-text-left">Profile Highlights</h2>
                            <p style={{ color: "#bbb", fontSize: "14px", marginTop: "-20px", marginBottom: "20px" }}>A quick glance at some key</p>
                            {/* Horizontal Scrollable Container */}
                            <div className="content" style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'auto', gap: '20px' }}>

                                {/* Followers Count Card */}
                                <div className="content-box" style={{ minWidth: "250px", height: '100px', padding: '20px', border: '1px solid #ddd', borderRadius: '30px', backgroundColor: '#f9f9f9', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <span className="icon is-large" style={{ marginRight: '10px', color: 'blue' }}>
                                        <i className="fas fa-users"></i>
                                    </span>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <p className="subtitle is-5" style={{ margin: 0 }}>{userData.followersCount || 'N/A'}</p>
                                        <h4 className="title is-6" style={{ margin: 0 }}>Total Followers</h4>
                                    </div>
                                </div>

                                {/* Average Likes Card */}
                                <div className="content-box" style={{ minWidth: "200px", height: '100px', padding: '20px', border: '1px solid #ddd', borderRadius: '30px', backgroundColor: '#f9f9f9', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <span className="icon is-large" style={{ marginRight: '10px', color: 'red' }}>
                                        <i className="fas fa-heart"></i>
                                    </span>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <p className="subtitle is-5" style={{ margin: 0 }}>{userData.avgLikes || 'N/A'}</p>
                                        <h4 className="title is-6" style={{ margin: 0 }}>Avg Likes</h4>
                                    </div>
                                </div>

                                {/* Average Comments Card */}
                                <div className="content-box" style={{ minWidth: "250px", height: '100px', padding: '20px', border: '1px solid #ddd', borderRadius: '30px', backgroundColor: '#f9f9f9', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <span className="icon is-large" style={{ marginRight: '10px', color: 'blue' }}>
                                        <i className="fa fa-comment"></i>
                                    </span>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <p className="subtitle is-5" style={{ margin: 0 }}>{userData.avgComments || 'N/A'}</p>
                                        <h4 className="title is-6" style={{ margin: 0 }}>Avg Comments</h4>
                                    </div>
                                </div>

                                {/* Average Reposts Card */}
                                <div className="content-box" style={{ minWidth: "200px", height: '100px', padding: '20px', border: '1px solid #ddd', borderRadius: '30px', backgroundColor: '#f9f9f9', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <span className="icon is-large" style={{ marginRight: '10px', color: 'green' }}>
                                        <i className="fas fa-retweet"></i>
                                    </span>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <p className="subtitle is-5" style={{ margin: 0 }}>{userData.avgReposts || 'N/A'}</p>
                                        <h4 className="title is-6" style={{ margin: 0 }}>Avg Reposts</h4>
                                    </div>
                                </div>

                                <div className="content-box" style={{ minWidth: "250px", height: '100px', padding: '20px', border: '1px solid #ddd', borderRadius: '30px', backgroundColor: '#f9f9f9', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <span className="icon is-large" style={{ marginRight: '10px', color: 'yellow' }}>
                                        <i className="fas fa-chart-line"></i>
                                    </span>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <p className="subtitle is-5" style={{ margin: 0 }}>{averageEngagementRate.toFixed(2)}%</p>
                                        <h4 className="title is-6" style={{ margin: 0 }}>Avg Eng.
                                        </h4>
                                    </div>
                                </div>


                            </div>
                            {/* New Engagement Rate Card */}

                        </div>
                    </div>
                    <div className="card" style={{ marginLeft: "-127px" }} >
                    <h2 className="title is-4 mb-5" style={{ color: "black", marginLeft:"20px" }}>Engagement Rate</h2>
                        <div className="card-content" >
                            <EngagementChart data={engagementData} />
                        </div>
                    </div>

                    {/* <div className="card" style={{ marginLeft: "-120px", marginTop: "20px", overflowX: 'auto' }}>
                        <div className="card-content">
                            <h2 className="title is-4" style={{ color: "black" }}>Most Engaged Posts</h2>
                            <p style={{ color: "#bbb", fontSize: "14px" }}></p>

                            <div className="content" style={{ display: 'flex', justifyContent: 'flex-start', overflowX: 'auto', gap: '20px' }}>
                                {mediaData.map((media) => (
                                    <div key={media.id} className="card" style={{
                                        minWidth: "300px",
                                        borderRadius: '15px',
                                        backgroundColor: '#2b2b2b',
                                        padding: '20px',
                                        color: '#fff'
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <img src={media.mediaUrl || 'default-media.png'} alt="Media" style={{
                                                borderRadius: '50%',
                                                width: '50px',
                                                height: '50px',
                                                marginRight: '10px'
                                            }} />
                                            
                                        </div>

                                        <p className="content" style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            marginBottom: '10px'
                                        }}>{media.caption}</p>

                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p><i className="fas fa-heart"></i> {media.likeCount}</p>
                                            <p><i className="fas fa-comment"></i> {media.comments_count}</p>
                                            <p><i className="fas fa-share"></i> {media.shareCount}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        {/* Show Media Button (outside the card) */}
                        {!showMediaData && (
                            <button className="button is-primary" onClick={handleShowMediaClick}>
                                Show Media
                            </button>
                        )}
                    </div>

                    {/* Conditionally render media data when button is clicked */}
                    {showMediaData && (
                        <>
                            {/* Hide Media Button (above the card) */}
                            <div style={{ textAlign: 'center', marginTop: '-10px' }}>
                                <button className="button is-primary" onClick={handleShowMediaClick}>
                                    Hide Media
                                </button>
                            </div>

                            {/* Media card with media content */}
                            <div className="card" style={{ width: "760px", marginTop: '10px', marginLeft: "-119px", padding: '20px' }}>
                            <h2 className="title is-4" style={{ color: "black" }}>Posts</h2>
                                <div className="media-content" style={{ marginTop: '20px', overflowX: 'auto', display: 'flex', gap: '20px' }}>
                                    {mediaData.length > 0 ? (
                                        mediaData.map(media => (
                                            <div key={media.id} className="media-item" style={{
                                                minWidth: '200px',
                                                maxWidth: '200px',
                                                textAlign: 'center',
                                                border: '1px solid #ddd',
                                                borderRadius: '10px',
                                                padding: '10px',
                                                backgroundColor: '#f9f9f9',
                                            }}>
                                                <img src={media.mediaUrl} alt="Media" style={{
                                                    width: '200px',
                                                    height: '240px',
                                                    objectFit: 'cover',
                                                    borderRadius: '5px'
                                                }} />
                                                <p style={{ fontSize: '12px', marginTop: '10px' }}>{media.caption}</p>

                                                {/* Like Count and Comment Count */}
                                                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                        <i className="fas fa-heart" style={{ color: 'red' }}></i> {/* Heart Icon */}
                                                        <span style={{ fontSize: '12px' }}>{media.likeCount}</span> {/* Like Count */}
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }} onClick={() => toggleComments(media.id)}>
                                                        <i className="fas fa-comment" style={{ color: 'gray' }}></i> {/* Comment Icon */}
                                                        <span style={{ fontSize: '12px' }}>{media.comments_count}</span> {/* Comment Count */}
                                                    </div>
                                                </div>

                                                {/* Conditionally render comments if commentVisibility is true for this media */}
                                                {commentVisibility[media.id] && (
                                                    <div style={{ marginTop: '10px', textAlign: 'left' }}>
                                                        <h4 style={{ fontSize: '14px' }}>Comments:</h4>
                                                        {media.comments.length > 0 ? (
                                                            media.comments.map((comment, index) => (
                                                                <div key={comment.id} style={{ fontSize: '12px', marginTop: '5px' }}>
                                                                    <strong>{comment.username}</strong>: {comment.text}
                                                                    {comment.reply_count > 0 && <span> ({comment.reply_count} replies)</span>}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p style={{ fontSize: '12px' }}>No comments available</p>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <p>No media data available</p>
                                    )}
                                </div>
                            </div>
                        </>
                    )}



                </div>




            </div>



        </div >
    );
};

export default UserProfile;
