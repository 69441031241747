import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384', '#36A2EB']; // Expanded color options

const renderLabel = ({ name, value }) => `${name}: ${value}`;

const DemographicPieChart = ({ data }) => {
  // Safeguard against undefined or empty data
  if (!data || !data.length) {
    return <p>No data available</p>;
  }

  // Transform the demographic data for the pie chart
  const chartData = data.flatMap(demographic =>
    demographic.totalValue.map(item => ({
      name: item.gender,
      value: item.value,
    }))
  );

  return (
    <PieChart width={400} height={400}>
      <Pie
        data={chartData}
        cx="50%"
        cy="50%"
        outerRadius={150}
        fill="#8884d8"
        dataKey="value"
        label={renderLabel}
      >
        {chartData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
};

export default DemographicPieChart;
