import React from 'react';
// import 'bulma/css/bulma.min.css'; // Import Bulma CSS
import { Config } from '../.env.js';
const Instagram = () => {
  const handleLogin = () => {
    window.location.href = `${Config.API_URL}/auth/facebook`; 
  };

  return (
    <div className="container">
      <div className="section">
        <div className="columns is-centered">
          <div className="column is-half">
            <button className="button is-primary is-fullwidth" onClick={handleLogin}>
              Login with Facebook
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instagram;
