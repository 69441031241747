// frontend/src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Instagram from './pages/Instagram';
import LoginSuccess from './pages/LoginSuccess';
import DataDisplay from './pages/DataDisplay';
import UserList from './pages/UsersList';
import UserDetailPage from './pages/UserDetailPage';
import 'bulma/css/bulma.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import UserPageList from './pages/userpage';
import UserProfile from './pages/userProfile';


const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Instagram />} />

          <Route path="/login-success" element={<LoginSuccess />} />
          <Route path="/data" element={<DataDisplay />} />
          <Route path = "/user" element={<UserList/>}/>
          <Route path="/user/:instagramAccountId" element={<UserDetailPage/>} />
          <Route path = "/my-user" element = {<UserPageList/>}/>
          <Route path = "/userProfile/:instagramAccountId" element = {<UserProfile/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
};


export default App;
