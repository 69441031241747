import React from 'react'

const LoginSuccess = () => {
  return (
    <div>
        <h1>Login Successfully</h1>
    </div>
  )
}

export default LoginSuccess