import React from 'react';
import AgeRangeLineChart from './AgeRangeLineChart';
import CountryCodeBarChart from './CountryCodeBarChart';
import DemographicPieChart from './DemographicPieChart';

const AudienceDataCharts = ({ ageRangeData, countryCodeData, demographicData }) => {
  return (
    <div className="card">
      <div className="card-content">
        <div className="columns is-multiline is-mobile">
          {/* Demographic Pie Chart */}
          <div className="column is-one-third">
            <div className="chart-container">
              <h4 className="title is-6">Followers Demographic</h4>
              {demographicData.length > 0 ? (
                <DemographicPieChart data={demographicData} />
              ) : (
                <p>No demographic data available</p>
              )}
            </div>
          </div>
          {/* Age Range Line Chart */}
          <div className="column is-one-third">
            <div className="chart-container">
              <h4 className="title is-6">Followers Age Range</h4>
              {ageRangeData.length > 0 ? (
                <AgeRangeLineChart data={ageRangeData} />
              ) : (
                <p>No age range data available</p>
              )}
            </div>
          </div>
          {/* Country Code Bar Chart */}
          <div className="column is-one-third">
            <div className="chart-container">
              <h4 className="title is-6">Country Code Distribution</h4>
              {countryCodeData.length > 0 ? (
                <CountryCodeBarChart data={countryCodeData} />
              ) : (
                <p>No country code data available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudienceDataCharts;
